/* eslint-disable no-unused-expressions */
import "bootstrap/dist/js/bootstrap.min.js";
import "./src/styles/global.scss"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.debug(`# IntersectionObserver is polyfilled!`)
  }
}
